module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"portfolio":"/Users/alanfung/Documents/Projects/Code/gatsby-portfolio/src/templates/portfolio.js","journal":"/Users/alanfung/Documents/Projects/Code/gatsby-portfolio/src/templates/journal.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1600,"linkImagesToOriginal":false}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Montserrat:400,500,900","Open Sans"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-163309460-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alan Fung | Portfolio","short_name":"AF","start_url":"/","background_color":"#1a1a1a","theme_color":"#1a1a1a","display":"minimal-ui","icon":"src/images/logo.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
